import { useRouter } from 'next/router'

import BaseHeader from './BaseHeader';



function Header() {
  const router = useRouter();

  // console.log(user);

  // const { data: secretMessage } = api.post.getSecretMessage.useQuery(
  //     undefined, // no input
  //     { enabled: sessionData?.user !== undefined }
  // );

  // If on the main page with that video, dont show a header
  if (router.route == "/") {
    return <div className="h-0" />;
  }

  // Else if on fans landing page
  else if (router.route == "/landing/fans") {
    return <BaseHeader
        showWaitlistButton={false}
        showLoginButton={true}
        waitlistURL={"/waitlist/fans"}
        backgroundColor="#FFF3E5"
        altColor="#000000"
        menuIconColor="#000000"
      />
  }
  // Else if on fans waitlist
  else if (router.route == "/waitlist/fans") {
    return <BaseHeader
        showWaitlistButton={false}
        showLoginButton={false}
        backgroundColor="#FFF3E5"
        altColor="#000000"
        menuIconColor="#000000"
      />
  }
  // Else if on fans login
  else if (router.route == "/login/fans") {
    return <BaseHeader

        showWaitlistButton={false}
        showLoginButton={false}
        backgroundColor="#000000"
        altColor="#000000"
        menuIconColor="#FFF3E5"

      />
  }
  // Else if on fans signup
  else if (router.route == "/signup/fans") {
    return <BaseHeader
        showWaitlistButton={false}
        showLoginButton={false}
        backgroundColor="#000000"
        altColor="#000000"
        menuIconColor="#FFF3E5"

      />
  }

  // Else if on artist landing page
  else if (router.route == "/landing/artists") {
    return <BaseHeader
        showWaitlistButton={true}
        showLoginButton={false}
        waitlistURL={"/waitlist/artists"}
        backgroundColor="#000000"
        altColor="#FFFFFF"
        menuIconColor="#FFFFFF"


      />
  }
  // Else if on artist waitlist
  else if (router.route == "/waitlist/artists") {
    return <BaseHeader
        showWaitlistButton={false}
        showLoginButton={false}
        backgroundColor="#000000"
        altColor="#FFFFFF"
        menuIconColor="#FFFFFF"

      />
  }
  // Else if on artist login
  else if (router.route == "/login/artists") {
    return <BaseHeader
        showWaitlistButton={false}
        showLoginButton={false}
        backgroundColor="#000000"
        altColor="#FFFFFF"
        menuIconColor="#FFFFFF"


      />
  }
  // Else if on artist signup
  else if (router.route == "/signup/artists") {
    return <BaseHeader
        showWaitlistButton={false}
        showLoginButton={false}
        backgroundColor="#000000"
        altColor="#FFFFFF"
        menuIconColor="#FFFFFF"

      />
  }

  else if (router.route == "/profile/artist/[slug]") {
    return <BaseHeader
        showWaitlistButton={false}
        showLoginButton={true}
        backgroundColor="#000000"
        altColor="#FFFFFF"
        menuIconColor="#FFFFFF"
      />
  }

  return (
    <BaseHeader
      showWaitlistButton={false}
      showLoginButton={true}
      backgroundColor="#FFF3E5"
      altColor="#000000"
      menuIconColor="#000000"


    />
  );
}

export default Header;