import { createContext, useContext, useState } from "react";

import {
  AlertDialog as UIAlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@diehard/components/alertdialog/alert-dialog";


type AlertDialogProps = {
  title: string
  description?: string
  cancel?: string
  action?: string
  onCancel?: () => void
  onAction?: () => void
}

type AlertContextType = {
  showAlert: (message: AlertDialogProps) => void
  hideAlert: () => void
}

const AlertContext = createContext<AlertContextType>({
  showAlert: (message: AlertDialogProps) => console.log(message),
  hideAlert: () => console.log('hide')
})

export const useAlert = () => useContext(AlertContext);

const AlertDialog: React.FC<AlertDialogProps> = ({ title, description, cancel, action, onCancel, onAction }) => {
  return (
    <UIAlertDialog defaultOpen>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          {description && <AlertDialogDescription>{description}</AlertDialogDescription>}
        </AlertDialogHeader>
        <AlertDialogFooter>
          {cancel && <AlertDialogCancel onClick={() => typeof onCancel === 'function' && onCancel()}>{cancel}</AlertDialogCancel>}
          <AlertDialogAction className="bg-dh-orange" onClick={() => typeof onAction === 'function' && onAction()}>{action || 'OK'}</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </UIAlertDialog>
  )
}

export const AlertProvider = ({ children }: {children: React.ReactNode}) => {
  const [alert, setAlert] = useState<AlertDialogProps>();

  const showAlert = (message: AlertDialogProps) => {
    setAlert(message);
  };

  const hideAlert = () => {
    setAlert(undefined);
  };

  return (
    <AlertContext.Provider value={{ showAlert, hideAlert }}>
      {children}
      {alert && (
        <AlertDialog
          {...alert}
        />
      )}
    </AlertContext.Provider>
  );
};


