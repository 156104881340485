import { createBrowserClient, type CookieOptions } from '@supabase/ssr'
import { deleteCookie, getCookie, setCookie } from 'cookies-next'

import { env } from "../../env.mjs"

export function createClient() {
  const supabase = createBrowserClient(
    env.NEXT_PUBLIC_SUPABASE_URL,
    env.NEXT_PUBLIC_SUPABASE_ANON_KEY,
    {
      auth: {
        storageKey: "die-hard-auth-token"
      }, 
      cookies: {
        get(name: string) {
          return getCookie(name) as string | undefined
        },
        set(name: string, value: string, options: CookieOptions) {
          setCookie(name, value, options);
        },
        remove(name: string, options: CookieOptions) {
          deleteCookie(name, options)
        },
      },
    }
  )

  return supabase
}