// Converts an object { id: "value", id2: "value2", ... } to "?id=value&id2=value2&..."
// Useful for crafting query params for URL
export function objectToQueryString(obj?: Record<string, string>): string {
    if (obj) {
      const queryParams = Object.entries(obj)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');
  
    return `?${queryParams}`;
    }
    return '';
  }