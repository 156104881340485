import Head from 'next/head';
import { type AppType, type AppProps } from "next/app";
import { Archivo, Space_Grotesk, Archivo_Black, Bebas_Neue, Libre_Franklin, Lacquer } from 'next/font/google'
import localFont from 'next/font/local'

import Header from '@diehard/components/header/Header'
import { api } from "@diehard/utils/api";
import { Providers } from '@diehard/components/providers/Providers';

import "@diehard/styles/embla.css";
import "@diehard/styles/revolving.css";
import "@diehard/styles/globals.css";



const archivo = Archivo({
  subsets: ["latin"],
  variable: "--font-archivo",
  weight: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
});

const archivo_black = Archivo_Black({
  subsets: ["latin"],
  variable: "--font-archivo_black",
  weight: ["400"],
});

const space_grotesk = Space_Grotesk({
  subsets: ["latin"],
  variable: "--font-space_grotesk",
  weight: ["300", "400", "500", "600", "700"],
});

const bebas_neue = Bebas_Neue({
  subsets: ["latin"],
  variable: "--font-bebas_neue",
  weight: ["400"],
});

const libre_franklin = Libre_Franklin({
  subsets: ["latin"],
  variable: "--font-libre_franklin",
  weight: ["300","600"]
})

const lacquer = Lacquer({
  subsets: ["latin"],
  variable: "--font-lacquer",
  weight: ["400"]
})

// Font files can be colocated inside of `pages`
const akira_expanded = localFont({ 
  src: '../components/fonts/akira_expanded.woff', 
  variable: "--font-akira_expanded" 
});
const bayside_demo = localFont({ 
  src: '../components/fonts/bayside_demo.woff', 
  variable: "--font-bayside_demo" 
});

const MyApp: AppType = ({ Component, pageProps: { ...pageProps } }: AppProps) => {
  
  
  return (
    <Providers>
      <Head key="main-head">
        <title>Die-Hard</title>
        
        <meta property="og:title" content="Die-Hard" key="title" />
        <meta name="description" content="" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <style jsx global>{`
        :root {
          --font-archivo: ${archivo.style.fontFamily};
          --font-archivo_black: ${archivo_black.style.fontFamily};
          --font-akira_expanded: ${akira_expanded.style.fontFamily};
          --font-bebas_neue: ${bebas_neue.style.fontFamily};
          --font-libre_franklin: ${libre_franklin.style.fontFamily};
          --font-space_grotesk: ${space_grotesk.style.fontFamily};
          --font-lacquer: ${lacquer.style.fontFamily};
          --font-bayside_demo: ${bayside_demo.style.fontFamily}
        }
      `}</style>
      <Header />
      <Component {...pageProps} />
    </Providers>
  );
};

export default api.withTRPC(MyApp);