import { type ReactNode } from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from "@vercel/speed-insights/next"
import { Toaster } from 'react-hot-toast';

import { AlertProvider } from '@diehard/components/providers/AlertDialog';

const theme = createTheme({
    typography: {
      fontFamily: '"Archivo",sans-serif'
    }
  })

export function Providers({ children }:{ children: ReactNode[] }) {
    return (
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <AlertProvider>
            {children}
            <Toaster />
            <Analytics />
            <SpeedInsights/>
          </AlertProvider>
        </LocalizationProvider>
      </ThemeProvider>
    )
}