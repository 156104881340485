/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, type ReactNode, useEffect } from "react";
import React from "react";

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { AppBar, Box, IconButton, Link, Menu, MenuItem, Toolbar } from "@mui/material";
import { type User } from "@supabase/supabase-js";

import { createClient } from "@diehard/utils/supabase/component";
import toast from "react-hot-toast";
import { objectToQueryString } from "@diehard/utils/objectToString";
import { api } from "@diehard/utils/api";
import { useRouter } from "next/router";

interface BaseHeaderProps {
// TODO: implement the URL when we have a logo to use
// logoURL: string;

showWaitlistButton: boolean;
waitlistURL?: string;

showLoginButton: boolean;
 
backgroundColor: string;
altColor: string;


menuIconColor: string;
}

function BaseHeader ({ showWaitlistButton, showLoginButton, waitlistURL, backgroundColor, altColor, menuIconColor }: BaseHeaderProps) {
  const supabase = createClient();
  const router = useRouter();
  console.log(showWaitlistButton, showLoginButton)
  const [ user, setUser ] = useState<User | null>(null);

  useEffect(() => {
    async function updateUser() {
      await supabase.auth.getUser().then((data) => {
        setUser(data.data.user);
      })
    }

    const { data } = supabase.auth.onAuthStateChange((event) => {
      if (event === 'SIGNED_OUT') {
        updateUser();
        router.reload();
      }
      updateUser();
    });
    
    return () => { data.subscription }
  }, []);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleSignIn = async () => {
    if (router.pathname.startsWith("/landing/")) {
      router.push("/login/fans")
    }
    else {
      router.push("/login/fans"+objectToQueryString({ redirect_to: router.asPath }));
    }
  }

  const handleSignOut = async () => {
    const { error } = await supabase.auth.signOut()

    if (error) {
      toast.error(error.name + ": " + error.message, { id:"signout_error" })
    }
    else {
      router.push("/")
    }
  }

  // some weird bug with black bg
  let backgroundColorString = "bg-black";
  if (backgroundColor == "#000000") {
      backgroundColorString = "bg-black"
  }
  else {
      backgroundColorString = `bg-[${backgroundColor}]`;
  }
  const altBorderString = `border-[${altColor}]`;
  const altTextString = `text-[${altColor}]`;
    
    return(
     <div id='header-outer' className={`sticky top-0 z-50 flex w-full items-center justify-between border-0 bg-black p-6 max-h-[90px] h-[90px] shadow-md`}>
        <Link className="flex" href="/" underline="none">
          <Box className="h-[30px]" component="img" src="https://ovtxysbssuvneuxqphcv.supabase.co/storage/v1/object/public/assets/general/headerlogo.png"/>
        </Link>

        {/** Menu on Desktop */}
        <Box sx={{ display: { xs: 'none', md:'flex' } }}>
          {showWaitlistButton && (
              <Link className={`flex`} 
              href={`${waitlistURL ? waitlistURL : ''}`} 
              underline="none">
              <button className={`flex items-center rounded-full border outline ${altBorderString} ${backgroundColorString} px-4 py-2 ${altTextString} focus:outline-none`}>
                  WAITLIST&nbsp;
                  <ArrowForwardIcon sx={{ color: altColor}} />
              </button>
              </Link>
          )}


          {/* spacer when both buttons are showing */}
          { (showLoginButton && showWaitlistButton) && (
              <div className= "p-4" />
          )}
              
          {/* login button - conditionally render based on existing session */}
          {showLoginButton && (
              <div>
              {user ? 
                  <div className="space-x-4 horizontal">
                    <button 
                    className ={`flex items-center rounded-full border outline ${altBorderString} ${backgroundColorString} px-4 py-2 ${altTextString} focus:outline-none`}
                    onClick={() => router.push("/fans")}
                    >
                    My Profile
                    </button>
                    <button 
                    className ={`flex items-center rounded-full border outline ${altBorderString} ${backgroundColorString} px-4 py-2 ${altTextString} focus:outline-none`}
                    onClick={handleSignOut}
                    >
                    Log out
                    </button>
                  </div>
                  :
                  <button 
                  className ={`flex items-center rounded-full border outline ${altBorderString} ${backgroundColorString} px-4 py-2 ${altTextString} focus:outline-none`}
                  onClick={handleSignIn}
                  >
                  Log in
                  <ArrowForwardIcon sx={{ color: altColor}} />
                  </button>
              }
              </div>
          )}
        </Box>

        {/** Menu on Mobile */}
        <Box id= 'menu-on-mobile' sx={{ display: { xs: 'flex', md:'none' } }}>
          <IconButton size="large" sx={{ color: `${menuIconColor}` }} aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu}>
            <MenuRoundedIcon />
          </IconButton>
          <Menu id="menu-appbar" anchorEl={anchorElNav} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right' }} open={Boolean(anchorElNav)} onClose={handleCloseNavMenu}>
            {showWaitlistButton && (
              <MenuItem href={`${waitlistURL ? waitlistURL : ''}`} >Join Waitlist</MenuItem>
            )}
            {(showLoginButton && user?.id) ? <MenuItem onClick={() => router.push("/fans")}>My Profile</MenuItem> : []}
            {showLoginButton && (
              user 
              ? <MenuItem onClick={handleSignOut}>Log Out</MenuItem> 
              : <MenuItem onClick={handleSignIn}>Log In</MenuItem>
            )}
          </Menu>
        </Box>

    </div>
    )
}

export default BaseHeader;